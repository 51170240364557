import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFileCircleExclamation,
  faFileCode,
  faFileLines,
  faFilePdf,
  faFileZipper,
  faImage,
  faMusic
} from '@fortawesome/free-solid-svg-icons';
import { PatientType } from 'types/patientManagement';
import { FormSetting, SelectOptionGroupType } from '../types';
import { GeoLocationTreeType, GeoLocationType } from '../types/mdm';
import { EncounterReasonType } from '../types/encounterManagement';
import useAuthHook from 'hooks/useAuthHook';

export const getSystemTheme = () =>
  window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

export const getItemFromStore = (
  key: string,
  defaultValue?: string | boolean,
  store = localStorage
) => {
  try {
    return store.getItem(key) === null
      ? defaultValue
      : JSON.parse(store.getItem(key) as string);
  } catch {
    return store.getItem(key) || defaultValue;
  }
};

export const setItemToStore = (
  key: string,
  payload: string,
  store = localStorage
) => store.setItem(key, payload);

export const capitalize = (string: string) =>
  (string.charAt(0).toUpperCase() + string.slice(1))
    .replace(/-/g, ' ')
    .replace('_and_', '&');
export const makeTitle = (string: string, separator: string = '-') => {
  const words = string.split(separator);

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(' ');
};

export const getColor = (name: string) => {
  const dom = document.documentElement;
  return getComputedStyle(dom).getPropertyValue(`--phoenix-${name}`).trim();
};

export const getFileIcon = (fileFormat: string): IconProp => {
  switch (fileFormat) {
    case 'zip':
    case 'rar':
      return faFileZipper;
    case 'bat':
      return faFileCode;
    case 'txt':
      return faFileLines;
    case 'mad':
      return faFileCircleExclamation;
    case 'wav':
      return faMusic;
    case 'pdf':
      return faFilePdf;
    case 'jpg':
    case 'png':
    case 'jpeg':
      return faImage;
    default:
      return faFileLines;
  }
};

export const getIntegerArrayBetween = (start = 0, end: number): number[] =>
  new Array(end + 1 - start).fill(1).map((_, i) => i + start);

export const parseData = (data: string) => {
  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
};

export const hexToRgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b];
};

export const getFileExtension = (fileName: string, separator = '.') =>
  fileName.split(separator).pop() || 'unknown';

export const isImageFile = (file: File) => {
  const imageMimeTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/webp'
  ];
  return imageMimeTypes.includes(file.type);
};

export const convertFileToAttachment = (file: File) => ({
  name: file.name,
  size: `${(file.size / 1024).toFixed(2)} KB`,
  format: getFileExtension(file.name),
  preview: isImageFile(file) ? URL.createObjectURL(file) : undefined
});

export const convertGeoLocationTreeToFlattenOptions = (
  results: GeoLocationTreeType[],
  level = 0
) => {
  return results.reduce((acc: SelectOptionGroupType[], location) => {
    acc.push({
      label: `${location.name} (${location.location_level?.code})`,
      value: location.id,
      level
    });
    if (location.children) {
      acc = acc.concat(
        convertGeoLocationTreeToFlattenOptions(location.children, level + 1)
      );
    }
    return acc;
  }, []);
};
export const flattenOptions = (
  options: SelectOptionGroupType[],
  level = 0
): SelectOptionGroupType[] => {
  return options.reduce<SelectOptionGroupType[]>((acc, option) => {
    acc.push({ label: option.label, value: option.value, level });
    if (option.options) {
      acc = acc.concat(flattenOptions(option.options, level + 1));
    }
    return acc;
  }, []);
};

export const calculateAge = (dateString: string) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age <= 0 ? 1 : age;
};

// eslint-disable-next-line
export const getFullName = (data: any): string => {
  const { first_name = '', middle_name = '', last_name = '' } = data ?? {};
  const nameParts = [first_name, middle_name, last_name].filter(Boolean);
  return nameParts.join(' ').trim();
};

// eslint-disable-next-line
export const getFirstAndLastName = (data: any): string => {
  const { first_name = '', last_name = '' } = data ?? {};
  const nameParts = [first_name, last_name].filter(Boolean);
  return nameParts.join(' ').trim();
};

export const getFullKhmerName = (data?: PatientType | null) => {
  const { tenant } = useAuthHook();
  const {
    alt_first_name = '',
    alt_middle_name = '',
    alt_last_name = ''
  } = data ?? {};

  const fullName =
    tenant?.name?.toLowerCase() === 'blcp'
      ? [alt_last_name, alt_middle_name, alt_first_name]
      : [alt_first_name, alt_middle_name, alt_last_name];
  return fullName.filter(Boolean).join(' ');
};

// eslint-disable-next-line
export const serializedObject = (obj: any) => {
  return new URLSearchParams(obj).toString();
};

export const pageCount = (total: number, size: number) => {
  if (total === 0 || size === 0) {
    return 1;
  }

  const count = Math.ceil(total / size);
  return count;
};

export const showOrHideField = (formSettings: FormSetting[], field: string) => {
  return (
    formSettings.filter(
      data => data.field_name === field && (data.selected || data.default)
    ).length > 0
  );
};

export const isoToDateString = (
  dateString: string,
  format: 'YYYY-MM-DD' | 'DD/MM/YYYY' = 'YYYY-MM-DD'
) => {
  let date: Date;

  if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
    const [day, month, year] = dateString.split('-').map(Number);
    date = new Date(year, month - 1, day);
  } else {
    date = new Date(dateString);
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  let formattedDate;

  if (format === 'YYYY-MM-DD') {
    formattedDate = `${year}-${month}-${day}`;
  } else {
    formattedDate = `${day}/${month}/${year}`;
  }

  return formattedDate;
};

export const combinedLocation = (
  results: GeoLocationType[],
  locations: string[]
) => {
  return results
    .filter(v => locations?.includes(v.code as string))
    .sort(
      (a, b) =>
        locations.indexOf(a.code as string) -
        locations.indexOf(b.code as string)
    )
    .map(data => data.name)

    .join(', ');
};

export const getEncounterReason = (
  reasons: EncounterReasonType[],
  encounter_reason: string
) => {
  const encounterReasonFilter = reasons.filter(
    e => e.value == encounter_reason
  );
  return encounterReasonFilter.length > 0 ? encounterReasonFilter[0] : null;
};
export const getEncounterReasonLabel = (
  reasons: EncounterReasonType[],
  encounter_reason: string
) => {
  const encounterDetail = getEncounterReason(reasons, encounter_reason);
  return encounterDetail?.name || encounter_reason;
};

export const getDistrictFromLocation = (
  locations: GeoLocationType[],
  location: string[]
) => {
  const fullLocation = combinedLocation(locations, location || []);
  const locationArray = fullLocation.split(',');
  const district = locationArray[1]?.trim();

  return district;
};
