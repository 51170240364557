import { IconProp } from '@fortawesome/fontawesome-svg-core';
// import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import {
  faBookBookmark,
  faChartPie,
  faHospitalUser,
  faLaptopFile,
  faLayerGroup,
  faLocationDot,
  faSchool,
  faSlidersH,
  faTooth,
  faUpload,
  faUser,
  faUsers
} from '@fortawesome/free-solid-svg-icons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  is_dynamic?: boolean;
  permission?: string;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon?: IconProp;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
  permission?: string;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: faChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'dashboard',
        path: '/',
        pathName: 'default-dashboard',
        active: true,
        icon: faChartPie
      }
    ]
  },
  {
    label: 'patient_management',
    icon: faHospitalUser,
    labelDisabled: true,
    permission: 'patient-list',
    pages: [
      {
        name: 'patient_management',
        icon: faHospitalUser,
        path: '/patient-management/patient',
        pathName: 'activity-type',
        permission: 'patient-list',
        active: true
      },
      {
        name: 'venue_management',
        icon: faSchool,
        path: '/actor-management/venue',
        pathName: 'venue',
        permission: 'actor-venue-list',
        active: true
      },
      {
        name: 'booking_management',
        icon: faBookBookmark,
        path: '/booking-management',
        pathName: '/booking-management',
        permission: 'booking-list',
        active: true
      },
      {
        name: 'health_center_management',
        icon: faHospitalUser,
        path: '/health-center-management',
        pathName: 'health-center-management',
        permission: 'ohe-csi-list',
        active: true
      },
      {
        name: 'ohe',
        icon: faTooth,
        path: '/ohe',
        pathName: 'ohe',
        permission: 'ohe-csi-list',
        active: true
      },
      {
        name: 'csi',
        icon: faLaptopFile,
        path: '/csi',
        pathName: 'csi',
        permission: 'ohe-csi-list',
        active: true
      },
      {
        name: 'bulk_upload',
        icon: faUpload,
        path: '/patient-management/bulk-upload',
        pathName: 'patient-management',
        permission: 'patient-bulk-import',
        active: true
      }
    ]
  },

  {
    label: '',
    icon: faHospitalUser,
    pages: [
      {
        name: 'geo_data_management',
        icon: faLocationDot,
        path: '/mdm/location',
        pathName: 'mdm-location',
        permission: 'mdm-location-list',
        active: true
      },
      {
        name: 'actor_management',
        icon: faLayerGroup,
        permission: 'actor-activitytype-update,actor-list',
        pages: [
          {
            name: 'activity_type',
            path: '/actor-management/activity-type',
            pathName: 'activity-type',
            active: true,
            permission: 'actor-activitytype-update'
          },
          {
            name: 'actor',
            path: '/actor-management/actor',
            pathName: 'actor',
            active: true,
            permission: 'actor-list'
          }
        ]
      },
      {
        name: 'user_management',
        icon: faUsers,
        permission: 'auth-user-list',
        pages: [
          {
            name: 'user',
            path: '/user-management/user',
            pathName: 'user',
            permission: 'auth-tenant-user-list',
            active: true
          },
          {
            name: 'role',
            path: '/user-management/role',
            pathName: 'role',
            permission: 'auth-role-list',
            active: true
          }
        ]
      },
      {
        name: 'settings',
        icon: faSlidersH,
        path: '/settings/settings',
        pathName: 'settings',
        permission: 'auth-tenant-setting-list',
        active: true
      },
      {
        name: 'profile',
        icon: faUser,
        path: '/profile',
        pathName: 'settings',
        active: true
      }
    ]
  }
];
